import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import AdjUploadFile from '../widget-adj-upload'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    'adj-upload-file': AdjUploadFile,
    Paginate
  },
  data: function() {
    return {
      loading: false,
      fileUpload: false,
      status: null,
      document: {
        start: null,
        end: null
      },
      adjustStatus: [
        { text: 'อนุมัติ', value: 'approved' },
        { text: 'ไม่อนุมัติ', value: 'not-approved' }
      ],
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
            key: 'createdAt', 
            label: 'วันที่', 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'td-top text-xs-1/2',
            sortable: true 
        },
        { 
            key: 'employee', 
            label: 'พนักงาน', 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
        },
        { 
            key: 'qty', 
            label: 'จำนวน', 
            sortable: true, 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            class: 'text-right' 
        },
        { 
            key: 'approver', 
            label: 'ผู้อนุมัติ', 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
        },
        { 
            key: 'approve', 
            label: 'อนุมัติ',
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2', 
            sortable: true 
        },
        { 
            key: 'status', 
            label: 'สถานะ', 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            sortable: true 
        },
        { 
            key: 'action', 
            label: '#', 
            thClass: 'font-weight-bold text-xs-1/2',
            tdClass: 'text-gray-400 td-top text-xs-1/2',
            class: 'text-right' 
        }
      ],
      items: [
        {
          _id: '123456',
          createdAt: new Date(),
          employee: 'กชมน',
          approver: '',
          qty: 200,
          status: '<label class="badge badge-info">On hold</label>'
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
        allowOutsideClick: false,
        focusConfirm: false,
        customClass: {
          popup: 'swal2-custom-rounded',
          closeButton: 'text-3xl swal2-custom-close',
          actions: 'justify-content-end',
          contant: 'd-flex flex-row justify-content-around px-2',
          title: 'd-none',
          confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
        },
        cancelButtonColor: '#dc4a38',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        showCloseButton: true,
      })
    },
    oncreate() {
      this.$router.push("/adjustment/added")
    },
    onFileUpload() {
      this.fileUpload = !this.fileUpload
    },
    onFileUploadClose(e) {
      this.fileUpload = false
    },
    onInitData() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}