import Drawer from '@/components/drawers'
import Loading from '@/components/widgets/Loading.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    selectedId:{
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      form: {
        file: null
      }
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      file: { required },
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
      }
    }
  },
  methods: {
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.$v.$reset()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onSubmitForm() {
      this.onCloseDrawer()
    }
  }
}